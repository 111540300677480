import React from 'react';
import {IOpenComponentProps, stepDataHook, StepTag} from '../../types';
import {StepHeader} from './StepHeader';
import {classes} from './Step.st.css';
import {Divider} from 'wix-ui-tpa';
import {useControllerProps} from '../../../Widget/ControllerContext';

export interface StepProps {
  index: number;
  isLastStep: boolean;
  title: string;
  stepTag: StepTag;
  dataHooks: stepDataHook;
  collapsedComponent?: () => JSX.Element;
  openComponent: (props: IOpenComponentProps) => JSX.Element;
  emptyComponent?: () => JSX.Element;
}

export const Step = ({
  index,
  isLastStep,
  title,
  stepTag,
  dataHooks,
  openComponent: OpenComponent,
  collapsedComponent: CollapsedComponent,
  emptyComponent: EmptyComponent,
}: StepProps) => {
  const {
    checkoutStore: {
      activeStep,
      updateStepOnStage,
      updateActiveStepName,
      sendStageExpandedBIEvent,
      sendEditStepClickedBIEvent,
    },
  } = useControllerProps();

  const isEmpty = activeStep.stepIndex < index;
  const isCollapsed = activeStep.stepIndex > index;
  const isOpen = activeStep.stepIndex === index;
  const shouldShowDivider = (isEmpty || isCollapsed) && !isLastStep;

  const onEditClicked = () => {
    updateStepOnStage(index, stepTag);
    sendEditStepClickedBIEvent(stepTag);
  };

  const onOpen = () => {
    updateActiveStepName(stepTag);
    sendStageExpandedBIEvent(stepTag, true);
  };

  return (
    <div data-hook={dataHooks.root} className={classes.stepContainer}>
      <StepHeader
        isEmpty={isEmpty}
        isCompleted={isCollapsed}
        label={title}
        onEdit={onEditClicked}
        dataHooks={dataHooks}
        isLastStep={isLastStep}
      />
      {isOpen && <OpenComponent onOpen={onOpen} />}
      {isCollapsed && CollapsedComponent && <CollapsedComponent />}
      {isEmpty && EmptyComponent && <EmptyComponent />}
      {shouldShowDivider && (
        <Divider className={classes.stepDivider} data-hook={dataHooks.divider} direction="horizontal" />
      )}
    </div>
  );
};
