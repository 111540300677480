import React from 'react';

export interface IOpenComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onOpen: () => void;
}

export interface StepsManagerProps {
  children: (React.ReactElement | null)[];
}

export type stepImplementationProps = {
  index?: number;
  isLastStep?: boolean;
};

export type stepDataHook = {
  root: string;
  header: string;
  collapsed: string;
  open?: string;
  empty?: string;
  edit?: string;
  continue?: string;
  editButton?: string;
  divider?: string;
};

export type ActiveStep = {
  stepName: StepTag | null;
  stepIndex: number;
};

export enum StepTag {
  customerDetails = 'contact-details',
  deliveryMethod = 'delivery-method',
  payment = 'payment-and-billing',
  placeOrder = 'place-order',
}
